import React, { useState } from 'react';


const SearchModal = ({ show, searchIndex, relatedCorpsIds, handleRelatedCorpID, handleRelatedCorpIDMultiSearch, onClose, isMobile }) => {

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  if (!show) return null;

  else if (show && isMobile) {
    return (
      <div className="z-[9999] fixed px-[32px] top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div style={{backgroundColor:'#1f1f1f', fontFamily:"open sans hebrew"}}
              className="p-8 shadow-md rounded-md rounded-xl text-white w-[100%] max-w-[500px]"
        >
          <button
            type="button"
            onClick={() => (handleRelatedCorpID(searchIndex), onClose())}
            className="w-full bg-gray-500 text-white text-lg p-2 rounded-md hover:bg-gray-600"
          >
            חיפוש חדש
            {/* Open new search */}
          </button>
          {
            relatedCorpsIds[searchIndex][2] === "0" ? null :
            <button
              type="button"
              onClick={() => (handleRelatedCorpIDMultiSearch(searchIndex), onClose())}
              className="mt-4 w-full bg-gray-500 text-white text-lg p-2 rounded-md hover:bg-gray-600"
            >
              הוסף להשוואה
              {/* Add to multiple search */}
            </button>
          }
          
          <button
            type="button"
            onClick={onClose}
            className="orange_color mt-4 w-full bg-gray-500 text-white text-lg p-2 rounded-md hover:bg-gray-600"
          >
            סגור
          </button>
        </div>
      </div>
    );
  }

  else {
    return null;
  }
};

export default SearchModal;
